<template>
    <v-container fluid>
      <v-card>
        <v-toolbar color="secondary" flat>
          <v-toolbar-title>Tax Groups</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="accent" small to="/new-tax-group">
            <v-icon left dark>mdi-plus</v-icon>New Tax Group
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Code</th>
                      <th class="text-left">Tax Group Name</th>
                      <th class="text-left">Category</th>
                      <th class="text-left">Rate %</th>
                      <th class="text-left">Active</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in taxGroups" :key="index">
                      <td>{{ item.code }}</td>
                      <td>{{ item.name }}</td>
                      <td v-if="item.category=='I'">Input</td>
                      <td v-else>Output</td>
                      <td>{{ item.rate }}</td>
                      <td>{{ item.inactive }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
</template>

<script>
export default {
  data: () => ({
    search: "",
    taxGroups: [],
    headers: [
      { text: "Code", value: "code" },
      { text: "Tax Group Name", value: "name" },
      { text: "Active", value: "inactive" },
      { text: "Category", value: "category" },
      { text: "Rate %", value: "rate" }
    ]
  }),
  methods: {
    getTaxGroups() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/taxgroup`)
        .then(res => {
          self.taxGroups = res;
          self.loading = false;
        })
        .catch(err => {
          this.$refs.snackbar.show(err, "red");
        });
    }
  },
  created() {
    this.getTaxGroups();
  }
};
</script>